<script setup lang="ts">
import type { Command } from '#ui/types'
import { useAppConfig } from '#app'
import { breakpointsTailwind, useBreakpoints, useStorage } from '@vueuse/core'

defineOptions({
  inheritAttrs: false,
})

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: undefined,
  },
  ui: {
    type: Object as PropType<Partial<typeof config.value>>,
    default: () => ({}),
  },
})
const emit = defineEmits(['update:modelValue'])
const { usingInput } = useShortcuts()
const appConfig = useAppConfig()

const latestRecordSaved = useStorage('latestRecordSaved', [] as Command[], localStorage)

const config = computed(() => ({
  padding: 'p-0 sm:p-4',
  rounded: 'rounded-none sm:rounded-lg',
  width: 'sm:max-w-5xl',
  height: 'h-dvh sm:h-[28rem]',
  commandPalette: {
    input: {
      height: 'h-[--header-height] sm:h-12',
      icon: {
        size: 'h-5 w-5',
        padding: 'ps-11',
      },
    },
    group: {
      command: {
        prefix: `!text-foreground after:content-['_>']`,
      },
    },
    container: 'scroll-py-10',
  },
  fileIcon: {
    name: 'i-heroicons-document-text',
  },
  default: {
    closeButton: {
      icon: appConfig.ui?.icons.close,
      color: 'gray' as const,
      variant: 'ghost' as const,
      size: 'sm' as const,
    },
  },
}))

const { search } = useAlgoliaSearch('reservations')

const groups = [
  {
    key: 'latestRecordSaved',
    label: 'Recent reservation searches',
    commands: latestRecordSaved.value.sort((a: any, b: any) => b.id - a.id),
  },
  {
    key: 'reservations',
    label: (q: string) => q && `Reservations matching “${q}”...`,
    search: async (q: any) => {
      if (!q) {
        return []
      }

      const reservations = await search({ query: q })

      return reservations.hits.map((r: Record<string, any>) => {
        const labelItems = [r.id, r.confirmation_number, r.certificate_number]
        return {
          id: r.id,
          label: labelItems.filter(Boolean).join(' | '),
          suffix: r.guests.map((g: any) => g.name).join(', '),
        }
      })
    },
  },
]

const breakpoints = useBreakpoints(breakpointsTailwind)

const { ui, attrs } = useUI('content.search', toRef(props, 'ui'), config, undefined, true)

const smallerThanSm = breakpoints.smaller('sm')

const { isDashboardSearchModalOpen } = useUIState()

const commandPaletteRef = ref<
    HTMLElement & { query: Ref<string>, results: { item: Command }[] }
>()

const isOpen = computed({
  get() {
    return props.modelValue !== undefined ? props.modelValue : isDashboardSearchModalOpen.value
  },
  set(value) {
    props.modelValue !== undefined
      ? emit('update:modelValue', value)
      : (isDashboardSearchModalOpen.value = value)
  },
})

async function onSelect(commands: Command[]) {
  if (commands.length === 0) {
    return
  }

  const command = commands[0]

  if (latestRecordSaved.value && latestRecordSaved.value.length >= 10) {
    latestRecordSaved.value.shift()
  }

  if (latestRecordSaved.value && latestRecordSaved.value.length < 10) {
    if (!latestRecordSaved.value.some(c => c.id === command.id)) {
      latestRecordSaved.value.push({
        ...command,
        dateAt: new Date().toISOString(),
      })
    }
  }

  await navigateTo(`/reservations/${command.id}/edit`, { replace: true })
  isOpen.value = false
}

// avoid conflicts between multiple meta_k shortcuts
const canToggleModal = computed(() => isOpen.value || !usingInput.value)

defineShortcuts({
  meta_k: {
    usingInput: true,
    whenever: [canToggleModal],
    handler: () => {
      isOpen.value = !isOpen.value
    },
  },
  escape: {
    usingInput: true,
    whenever: [isOpen],
    handler: () => {
      isOpen.value = false
    },
  },
})

defineExpose({
  commandPaletteRef,
})
</script>

<template>
  <UModal v-model="isOpen" :overlay="!smallerThanSm" :transition="!smallerThanSm" :ui="ui">
    <UCommandPalette
      ref="commandPaletteRef"
      :model-value="[]"
      :groups="groups"
      :ui="ui.commandPalette"
      :close-button="ui.default.closeButton"
      multiple
      v-bind="attrs"
      @update:model-value="onSelect"
      @close="isOpen = false"
    >
      <template v-for="(_, name) in $slots" #[name]="slotData: any">
        <slot :name="name" v-bind="slotData" />
      </template>
    </UCommandPalette>
  </UModal>
</template>

<style scoped></style>
