<script setup lang="ts">
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '~/components/ui/breadcrumb'

const { breadcrumbs } = useBreadcrumbs()

function showBreadcrumbItem(breadIndex: number) {
  return breadIndex < breadcrumbs.value.length - 1
}
</script>

<template>
  <Breadcrumb class="hidden md:flex">
    <BreadcrumbList>
      <template v-for="(breadcrumb, breadIndex) in breadcrumbs">
        <BreadcrumbItem v-if="showBreadcrumbItem(breadIndex)" :key="breadIndex">
          <BreadcrumbLink as-child>
            <NuxtLink :to="breadcrumb.path" class="capitalize">
              {{ breadcrumb.name }}
            </NuxtLink>
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator v-if="showBreadcrumbItem(breadIndex)" :key="`bS-${breadIndex}`" />
        <BreadcrumbItem v-else :key="breadIndex">
          <BreadcrumbPage class="capitalize">
            {{ breadcrumb.name }}
          </BreadcrumbPage>
        </BreadcrumbItem>
      </template>
    </BreadcrumbList>
  </Breadcrumb>
</template>

<style scoped></style>
