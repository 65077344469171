<script setup lang="ts">
import Toaster from '@/components/ui/toast/Toaster.vue'
import { useOnline } from '@vueuse/core'

import { links } from '~/config/sidebar'

const isCollapsed = ref(true)

// add lang attribute to html tag
useHead({
  htmlAttrs: {
    lang: 'en',
  },
})

const online = reactive(useOnline())
const toast = useToast()

watch(
  () => online.value,
  (value) => {
    if (!value) {
      toast.add({
        title: 'You are offline',
        description: 'You are not connected to the internet',
        icon: 'lucide:radio-tower',
      })
    }
  },
  { immediate: true },
)
</script>

<template>
  <div class="flex min-h-screen w-full flex-col bg-muted/40">
    <NuxtPwaManifest />
    <div
      class="flex flex-col sm:gap-4 sm:py-4"
      :class="[isCollapsed ? 'sm:pl-14' : 'sm:pl-56']"
    >
      <SideBar
        :links="links"
        :is-collapsed="isCollapsed"
      />

      <TheHeading />
      <main class="flex flex-1 gap-4 p-4 sm:px-6 sm:py-0 md:gap-8">
        <div class="grow">
          <slot />
        </div>
      </main>
    </div>
    <Toaster />
    <ClientOnly>
      <ContentSearch />
    </ClientOnly>
    <UNotifications />
    <!-- LayoutThePresenceIndicator / -->
  </div>
</template>

<style scoped></style>
