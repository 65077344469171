import type { LinkProp } from '~/types/settings'

export const links: LinkProp[] = [
  {
    title: 'Dashboard',
    icon: 'lucide:home',
    variant: 'default',
    href: '/',
    can: null,
  },
  {
    title: 'Reservations',
    icon: 'lucide:calendar-clock',
    variant: 'default',
    href: '/reservations',
    can: 'reservations.index',
  },
  {
    title: 'Reports',
    icon: 'lucide:line-chart',
    variant: 'default',
    href: '/reports',
    can: 'reports.index',
  },
  {
    title: 'Users',
    icon: 'lucide:users',
    variant: 'default',
    href: '/users',
    can: 'users.index',
  },
  {
    title: 'Settings',
    icon: 'lucide:sliders-horizontal',
    variant: 'default',
    href: '/settings',
    can: 'settings.index',
  },
]
